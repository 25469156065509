import React from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"
import initSmartOutline from "js-utils/src/smart-outline"

import SEO from "../components/seo"
import theme from "../theme"
// import "../config/yup"

import "../assets/stylesheets/app/app.scss"

const Main = styled.main``

class Layout extends React.Component {
  componentDidMount() {
    if (document) {
      initSmartOutline([
        `input:focus`,
        `button:focus`,
        `textarea:focus`,
        `select:focus`,
      ])
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <SEO />

        <Main>{this.props.children}</Main>
      </ThemeProvider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
