import { css } from "styled-components"
import { rem } from "polished"

const shapeNotched = ($size = rem(20)) => css`
  clip-path: polygon(
    ${$size} 0%,
    calc(100% - ${$size}) 0%,
    100% 50%,
    100% 50%,
    calc(100% - ${$size}) 100%,
    ${$size} 100%,
    0 50%,
    0 50%
  );
`

export default {
  shapeNotched,
}
