module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"en","name":"Time To Happiness","short_name":"TTH","description":"If you could know the exact time you have left to accomplish happiness in your life, would that change how you live it? Would knowing your days are numbered help you find meaning?","theme_color":"#081b33","background_color":"#f7f7f7","icon":"static/meta-images/icon-with-bg.png","include_favicon":false,"start_url":"/","display":"minimal-ui","cache_busting_mode":"name"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-11425486-40","anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
